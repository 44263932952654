import React, { useState } from "react";
import PropTypes from "prop-types";

import Topbar from "./Topbar";

const Layout = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="flex flex-col min-h-full relative">
      <main className="flex flex-col flex-1">{children}</main>
      <Topbar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <div className={`block md:hidden fixed z-[4] w-full h-full bg-menuback transition-all pointer-events-none	${openMenu && "backdrop-blur-sm pointer-events-auto"}`} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
