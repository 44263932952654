import React, { useRef, useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import Slider from "infinite-react-carousel";
import Cookies from 'universal-cookie';
import Lottie from 'react-lottie';
import useWindowDimensions from "../hooks/useDimensions";
import { RefContext } from "../contexts/RefContextProvider";
import { Fade, Zoom } from "@mui/material";
import * as logoAnimation from './animations/logo.json'
import * as matrixAnimation from './animations/Matrix.json'

const Landing = () => {
  const cookies = new Cookies();
  const nav = useNavigate();
  const screenWidth = useWindowDimensions().width
  const screenHeight = useWindowDimensions().height
  const { shopPlansRef, familyPlansRef, whyPriveRef, showIntroduceContents, setShowIntroduceContents } = useContext(RefContext);
  const [flagCountVisible, setFlagCountVisible] = useState(-1)
  const [startedIntroduceAnim, setStartedIntroduceAnim] = useState(false);
  const [startedIntroduceMobileAnim, setStartedIntroduceMobileAnim] = useState(false);
  const [startedFlagAnim, setStartedFlagAnim] = useState(false)
  const [showArrows, setShowArrows] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [doing] = useState(Array(10).fill(false))
  const [trying] = useState(Array(3).fill(false))
  let introduceRef = useRef(null)
  let plansRef = useRef(null)
  let smartPhoneRef = useRef(0)
  let smartPhoneImgRef = useRef(0)
  // let smartPhoneMobileGraRef = useRef(0)
  let smartPhoneMobileImgRef = useRef(0)
  let smartPhoneMobileRef = useRef(0)
  let textRef = useRef(0)
  let ref = useRef(null)
  let matrixRef = useRef(0)
  let matrixMobileRef = useRef(0)
  let phoneVPNRef = useRef(null)
  let phoneTextRef = useRef(null)
  let timer = useRef(null)

  const logoAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: logoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const matrixAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: matrixAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleClick = (i) => {
    if (i === 5 || i === 6) {
      return;
    }

    nav("/waitlist")
  }

  const handleClickTry = (i) => {
    nav("/waitlist")
    // let tmp = [...trying]
    // tmp[i] = true
    // setTrying(tmp)
    // setTimeout(() => nav("/waitlist"), 5000)
  }

  const handleAgree = () => {
    let date = new Date();
    date.setDate(date.getDate() + 30);
    cookies.set('agreed', true, { expires: date });
    if(cookies.get('agreed') === "true") setAgreed(true)
  }

  useMemo(() => {
    console.log(cookies.get("agreed"))
    if(cookies.get('agreed') === "true") setAgreed(true)
  }, [])

  useMemo(() => {
    if(startedIntroduceAnim && introduceRef?.current && smartPhoneRef?.current && smartPhoneImgRef?.current && matrixRef?.current) {
      smartPhoneImgRef.current.style.transform = ""
      setTimeout(() => {
        setShowIntroduceContents(true)
      }, 800)
      setTimeout(() => {
        if(screenWidth > 2000) smartPhoneRef.current.style.top = "15vw"
        else if(screenWidth > 1500) smartPhoneRef.current.style.top = (screenWidth/1440*3)+"vw"
        else smartPhoneRef.current.style.top = "-40px"

        if(screenWidth > 2000) smartPhoneRef.current.style.right = "3vw"
        else smartPhoneRef.current.style.right = screenWidth < 1340 ? "-230px" : "-130px"
        smartPhoneRef.current.style.transform = "scale("+screenWidth/1440+") rotate(10deg)"
        smartPhoneRef.current.style.zIndex = "5"
        introduceRef.current.style.zIndex = "6"
        setTimeout(() => {matrixRef.current.style.opacity = "1"}, 600)
      }, 600)
    }
  }, [startedIntroduceAnim, introduceRef, smartPhoneRef, smartPhoneImgRef, matrixRef, screenWidth])

  useMemo(() => {
    if(startedIntroduceMobileAnim && introduceRef?.current && smartPhoneMobileImgRef?.current && smartPhoneMobileRef?.current && matrixMobileRef?.current) {
      smartPhoneMobileImgRef.current.style.transform = "scale("+(375/screenWidth*3)+")"
      setTimeout(() => {
        smartPhoneMobileRef.current.style.transition = "0.5s"
        smartPhoneMobileRef.current.style.transform = "translate(50%, -50%) rotate(10deg)"
        smartPhoneMobileRef.current.style.top = (970/screenHeight * 50)+"vh"
        smartPhoneMobileRef.current.style.zIndex = "5"
        introduceRef.current.style.zIndex = "6"
        textRef.current.style.zIndex = "6"
        setShowIntroduceContents(true)
        setTimeout(() => {matrixMobileRef.current.style.opacity = "1"}, 500)
      }, 700)
    }
  }, [startedIntroduceMobileAnim, introduceRef, textRef, smartPhoneMobileImgRef, smartPhoneMobileRef, matrixMobileRef, screenWidth, screenHeight])

  useMemo(() => {
    if(screenWidth > 1024) {
      setShowIntroduceContents(false);
      setShowArrows(false)
      if(plansRef?.current) plansRef?.current.slickGoTo(1)
    } else {
      setShowIntroduceContents(false);
      setShowArrows(true)
      if(plansRef?.current) plansRef?.current.slickGoTo(2)
    }
  }, [screenWidth, plansRef])

  useMemo(() => {
    if(startedFlagAnim) {
      setFlagCountVisible(prev => prev +=1)
      timer.current = setInterval(() => setFlagCountVisible(prev => prev +=1 ), 100)
    }
  }, [startedFlagAnim])

  useMemo(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      if(ref?.current && phoneVPNRef?.current && phoneTextRef?.current) {
        const elementPosition = ref?.current?.offsetTop;
        if(elementPosition - window.innerHeight/2 > currentOffset) {
          // setFlagCountVisible(-1)
          // setStartedFlagAnim(false)
          // clearInterval(timer.current)
        }
        else if((screenWidth < 1024 && phoneVPNRef?.current?.offsetTop + phoneVPNRef?.current.getBoundingClientRect().height/2 < currentOffset) || (screenWidth >= 1024 && phoneTextRef?.current?.offsetTop + phoneTextRef?.current.getBoundingClientRect().height/2 < currentOffset)) {
          // setFlagCountVisible(-1)
          // setStartedFlagAnim(false)
          // clearInterval(timer.current)
        }
        else setStartedFlagAnim(true)
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref, phoneVPNRef, phoneTextRef, screenWidth] )

  useMemo(() => {
    if(flagCountVisible>10) clearInterval(timer)
  }, [flagCountVisible])

  return (
    <div className="overflow-hidden">
      <div className="ml-auto mr-auto pb-7 tiny:pb-[34px] px-6 mobile:px-[125px]">
        {/* Introduce */}
        <section className="flex flex-col-reverse lg:flex-row justify-between items-center mt-[99px] lg:mt-[223px] introduce" ref={introduceRef}>
          <div className="flex flex-col items-center lg:items-start" ref={textRef}>
            <Fade direction="up" in={showIntroduceContents} style={{ transitionDelay: 0 }}>
              <span className="text-[48px] leading-[57.28px] lg:text-[80px] 2xl:text-[5.5555vw] lg:leading-[95.47px] 2xl:leading-[6.629861vw] font-semibold relative z-[1]">The Privacy</span>
            </Fade>
            <Fade direction="up" in={showIntroduceContents} style={{ transitionDelay: 200 }}>
              <span className="text-[48px] leading-[57.28px] lg:text-[80px] 2xl:text-[5.5555vw] lg:leading-[95.47px] 2xl:leading-[6.629861vw] font-semibold gradient-text bg-fire relative z-[1]">5G Network.</span>
            </Fade>
            <Fade direction="up" in={showIntroduceContents} style={{ transitionDelay: 400 }}>
              <span className="mt-[11px] lg:mt-5 subtitle text-center lg:text-left max-w-[524px] 2xl:max-w-[36.388vw] relative z-[1]">
                <span className="font-semibold gradient-text bg-fire">Prive</span> is the first-ever fully anonymous <span className="font-semibold gradient-text bg-fire">5G</span> mobile network carrier that keeps your data and information <span className="font-semibold gradient-text bg-fire">private and secure</span> while providing reliable and <span className="font-semibold gradient-text bg-fire">blazing fast internet</span> and phone service <div className="hidden lg:inline">for your mobile devices.</div>
              </span>
            </Fade>
            <Fade direction="up" in={showIntroduceContents} style={{ transitionDelay: 600 }}>
              <div className="flex flex-col tiny:flex-row items-center mt-[357px] lg:mt-[30px] space-y-[13px] tiny:space-y-0 space-x-0 tiny:space-x-[18px] w-full lg:w-auto relative z-[3]">
                <button className="p-[1px] bg-buttonback w-full lg:w-[185px] 2xl:w-[12.8472vw] text-black button-text font-medium relative hover:text-white" onClick={() => handleClick(0)} disabled={doing[0]}>
                  <div className="flex justify-center items-center bg-fire w-full h-full relative z-[2]">
                    {doing[0] ?
                      <img className="prive-loading1" src="images/spinner1.png" alt="" />
                      :
                      <>View Plans</>
                    }
                  </div>
                  <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
                </button>
                <button className="p-[1px] bg-fire w-full lg:w-[237.43px] 2xl:w-[16.4881vw] button-text font-medium relative dark" onClick={() => handleClick(1)} disabled={doing[1]}>
                  <div className="flex justify-center items-center bg-sand w-full h-full space-x-[10px] relative z-[2]">
                    {doing[1] ?
                      <img className="prive-loading1" src="images/spinner2.png" alt="" />
                      :
                      <>
                        <span className="gradient-text bg-white">5 Day Free Trial</span>
                        <img className="arrow-right" src="images/arrow.svg" alt="" />
                        <img className="arrow-right-hover" src="images/arrow-right-hover.svg" alt="" />
                      </>
                    }
                  </div>
                  <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
                </button>
              </div>
            </Fade>
          </div>
          <div className="absolute top-[50vh] right-[50vw] block lg:hidden z-[7] transition-all"
            style={{ transform: "translate(50%, -50%) scale(1.5)"}}
            ref={smartPhoneMobileRef}
          >
            <img className="relative w-[410px] z-[1]" src="images/Phone Desktop.png" style={{transform: "scale(8)", transition: "0.5s ease-out"}} alt="" ref={smartPhoneMobileImgRef} />
            <div className="absolute top-1/2 left-1/2 opacity-0 w-[300px]" style={{transition: "0.5s linear", transform: "translateX(-50%) translateY(-50%) scale(1)" }} ref={matrixMobileRef}>
              <Lottie options={matrixAnimationOptions} />
            </div>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[30%] tiny:w-[20%] z-[2] mobileLogo">
              <Lottie options={logoAnimationOptions}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => setStartedIntroduceMobileAnim(true),
                  },
                ]}
              />
            </div>
          </div>
          <div className="absolute hidden lg:block top-[calc(50vh-500px)] right-[calc(50vw-500px)] z-[7] w-[fit-content]" style={{ transition: "0.7s", transform: "scale("+(screenWidth/1440*1.5)+")" }} ref={smartPhoneRef}>
            <img className="relative z-[2] w-[1000px]" src="images/Phone Desktop.png" alt="" style={{transform: "scale(7)", transition: "0.5s linear"}} ref={smartPhoneImgRef} />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" style={{transition: "0.5s linear"}} ref={matrixRef}>
              <Lottie options={matrixAnimationOptions} />
            </div>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[100px] z-[2]">
              <Lottie options={logoAnimationOptions}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => setStartedIntroduceAnim(true),
                  },
                ]}
              />
            </div>
          </div>
          <img className="absolute right-[-250px] top-[550px] lg:top-[650px] -translate-y-1/2 w-[600px] h-[650px] lg:h-[900px] blur-1xl" src="images/Light 7.png" alt="" />
        </section>

        {/* Anonymous */}
        <section className="flex flex-col-reverse lg:flex-row justify-between items-center mt-[113px] lg:mt-[371px] anonymous relative" ref={whyPriveRef}>
          <div className="flex flex-col items-center lg:items-start z-[1] mt-6 lg:mt-0">
            <span className="title">
              The <span className="gradient-text bg-fire">Carrier</span>
            </span>
            <span className="title">that Keeps you</span>
            <span className="title gradient-text bg-fire">Anonymous</span>
            <span className="mt-[11px] lg:mt-5 subtitle text-center lg:text-left mb-[18px] lg:mb-[31px] max-w-[545px] 2xl:max-w-[37.847222vw]">
              Our network has built-in <span className="font-semibold gradient-text bg-fire">privacy safeguards</span> that keep your information <span className="font-semibold gradient-text bg-fire">secure and anonymous</span>. We don’t even ask for your name or address. Just create an account and activate Prive on your phone to get immediate <span className="font-semibold gradient-text bg-fire">5G coverage.</span>
            </span>
            <button className="p-[1px] bg-fire button-text font-medium relative w-full lg:w-[225px] 2xl:w-[15.625vw] dark">
              <div className="flex justify-center items-center bg-sand w-full h-full space-x-[10px] relative z-[2]" onClick={() => handleClick(2)} disabled={doing[2]}>
                {doing[2] ?
                  <img className="prive-loading1" src="images/spinner2.png" alt="" />
                  :
                  <span className="gradient-text bg-white">Start 5 Days Free Trial</span>
                }
              </div>
              <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
            </button>
          </div>
          <video className="absolute top-0 right-0 hidden lg:block w-[500px] origin-top-right" autoPlay loop muted controls={false} playsInline style={{ transform: "scale(" + (screenWidth / 1440 + 0.1) + ")" }}>
            <source src="videos/Phone Undercover.mp4" type="video/mp4" />
          </video>
          <video className="flex-1 lg:hidden verytiny:w-[327px] w-full max-w-[327px] max-h-[327px]" autoPlay loop muted controls={false} playsInline>
            <source src="videos/Phone Undercover.mp4" type="video/mp4" />
          </video>
        </section>

        {/* 5G Coverage */}
        <section className="flex flex-col-reverse lg:flex-row justify-between items-center mt-[152px] lg:mt-[419px] coverage relative z-[2]">
          <div className="flex flex-col items-center lg:items-start z-[1] mt-[36.68px] lg:mt-0">
            <span className="title">Blazing Fast</span>
            <span className="title gradient-text bg-fire">5G Coverage</span>
            <span className="mt-[11px] lg:mt-5 subtitle text-center lg:text-left max-w-[545px] 2xl:max-w-[37.8472vw]">
              <span className="font-semibold gradient-text bg-fire">Prive Mobile</span> offers cutting-edge wireless service that is <span className="font-semibold gradient-text bg-fire">secure and private</span> by default, all while leveraging the two fastest <span className="font-semibold gradient-text bg-fire">5G networks</span> - T-Mobile and Verizon.
            </span>
            <div className="flex items-center mt-[31px] lg:mt-[22px] space-x-[10px] lg:space-x-[18px] w-full lg:w-auto">
              <button className="p-[1px] bg-buttonback w-full lg:w-[185px] 2xl:w-[12.8472vw] text-black button-text font-medium relative hover:text-white" onClick={() => handleClick(3)} disabled={doing[3]}>
                <div className="flex justify-center items-center bg-fire w-full h-full relative z-[2]">
                  {doing[3] ?
                    <img className="prive-loading1" src="images/spinner1.png" alt="" />
                    :
                    <>See Plans</>
                  }
                </div>
                <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
              </button>
              <button className="p-[1px] bg-fire w-full lg:w-[237.43px] 2xl:w-[16.4881vw] button-text font-medium relative dark" onClick={() => handleClick(4)} disabled={doing[4]}>
                <div className="flex justify-center items-center bg-sand w-full h-full space-x-[10px] relative z-[2]">
                  {doing[4] ?
                    <img className="prive-loading1" src="images/spinner2.png" alt="" />
                    :
                    <>
                      <span className="gradient-text bg-white">5 Day Free Trial</span>
                      <img className="arrow-right" src="images/arrow.svg" alt="" />
                      <img className="arrow-right-hover" src="images/arrow-right-hover.svg" alt="" />
                    </>
                  }
                </div>
                <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
              </button>
            </div>
          </div>
          <img className="absolute top-0 right-0 hidden lg:block max-w-[562px] origin-top-right" src="images/city.png" alt="" style={{ transform: "scale(" + (screenWidth / 1440) + ")" }} />
          <img className="flex-1 lg:hidden verytiny:w-[327px] w-full max-w-[327px] max-h-[214.73px]" src="images/city.png" alt="" />
        </section>

        {/* Advanced VPN */}
        <section className="flex flex-col lg:flex-row-reverse justify-between items-center mt-[154px] lg:mt-[495px] advanced-vpn" ref={ref}>
          <div className="absolute hidden lg:block left-[24px] mobile:left-[125px] origin-[0_50%]" style={{ transform: "scale(" + (screenWidth / 1440) + ")" }}>
            <div>
              <div className="flex relative w-[512px] h-[507.89px]">
                <img className="relative z-[2] h-[460px] self-end" src="images/www-pure.png" alt="" />
                <img className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[1066px] h-[1194px]" src="images/Light 3.png" alt="" />
                <div className="absolute top-0 right-[50px] w-full h-full flags">
                  <div className="absolute top-[18.1739%] right-[16.7378%] w-[6.4047%]">
                    <Zoom in={flagCountVisible>=2} timeout={500}>
                      <img src="images/flags/Flag 1.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[52.0241%] right-[22.8864%] w-[10.24767%]">
                    <Zoom in={flagCountVisible>=1} timeout={500}>
                      <img src="images/flags/Flag 2.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[9.5607%] right-[27.4124%] w-[7.6857%]">
                    <Zoom in={flagCountVisible>=5} timeout={500}>
                      <img src="images/flags/Flag 3.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[27.8208%] right-[24.7651%] w-[4.6114%]">
                    <Zoom in={flagCountVisible>=3} timeout={500}>
                      <img src="images/flags/Flag 4.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[4.22%] right-[6.2339%] w-[9.479%]">
                    <Zoom in={flagCountVisible>=6} timeout={500}>
                      <img src="images/flags/Flag 5.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[0%] right-[20.4953%] w-[4.6968%]">
                    <Zoom in={flagCountVisible>=4} timeout={500}>
                      <img src="images/flags/Flag 6.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[44.2721%] right-[4.099%] w-[10.5038%]">
                    <Zoom in={flagCountVisible>=8} timeout={500}>
                      <img src="images/flags/Flag 7.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[36.8647%] right-[17.7625%] w-[7.0025%]">
                    <Zoom in={flagCountVisible>=9} timeout={500}>
                      <img src="images/flags/Flag 8.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[22.308%] right-[0px] w-[13.407%]">
                    <Zoom in={flagCountVisible>=0} timeout={500}>
                      <img src="images/flags/Flag 9.png" alt="" />
                    </Zoom>
                  </div>
                  <div className="absolute top-[0%] right-[38.6848%] w-[10.2476%]">
                    <Zoom in={flagCountVisible>=7} timeout={500}>
                      <img src="images/flags/Flag 10.png" alt="" />
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex-1 lg:hidden verytiny:w-[327px] w-full" ref={phoneVPNRef}>
            <img className="relative z-[2]" src="images/www-pure.png" alt="" />
            <img className="absolute left-1/2 top-[60%] -translate-x-1/2 -translate-y-1/2 min-w-[500px] h-[500px]" src="images/Light 3.png" alt="" />
            <div className="absolute top-0 right-0 w-full h-full flags">
              <div className="absolute top-[18.1739%] right-[16.7378%] w-[6.4047%]">
                <Zoom in={flagCountVisible>=2} timeout={500}>
                  <img src="images/flags/Flag 1.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[52.0241%] right-[22.8864%] w-[10.24767%]">
                <Zoom in={flagCountVisible>=1} timeout={500}>
                  <img src="images/flags/Flag 2.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[9.5607%] right-[27.4124%] w-[7.6857%]">
                <Zoom in={flagCountVisible>=5} timeout={500}>
                  <img src="images/flags/Flag 3.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[27.8208%] right-[24.7651%] w-[4.6114%]">
                <Zoom in={flagCountVisible>=3} timeout={500}>
                  <img src="images/flags/Flag 4.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[4.22%] right-[6.2339%] w-[9.479%]">
                <Zoom in={flagCountVisible>=6} timeout={500}>
                  <img src="images/flags/Flag 5.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[0%] right-[20.4953%] w-[4.6968%]">
                <Zoom in={flagCountVisible>=4} timeout={500}>
                  <img src="images/flags/Flag 6.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[44.2721%] right-[4.099%] w-[10.5038%]">
                <Zoom in={flagCountVisible>=8} timeout={500}>
                  <img src="images/flags/Flag 7.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[36.8647%] right-[17.7625%] w-[7.0025%]">
                <Zoom in={flagCountVisible>=9} timeout={500}>
                  <img src="images/flags/Flag 8.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[22.308%] right-[0px] w-[13.407%]">
                <Zoom in={flagCountVisible>=0} timeout={500}>
                  <img src="images/flags/Flag 9.png" alt="" />
                </Zoom>
              </div>
              <div className="absolute top-[0%] right-[38.6848%] w-[10.2476%]">
                <Zoom in={flagCountVisible>=7} timeout={500}>
                  <img src="images/flags/Flag 10.png" alt="" />
                </Zoom>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center lg:items-start relative z-[2] mt-10 lg:mt-0 2xl:pr-10" ref={phoneTextRef}>
            <span className="title gradient-text bg-fire">Advanced VPN</span>
            <span className="title">Built-in</span>
            <span className="mt-3 lg:mt-5 subtitle text-center lg:text-left max-w-[545px] 2xl:max-w-[37.8472vw]">
              <span className="font-semibold gradient-text bg-fire">The Prive app</span> has proprietary free built-in <span className="font-semibold gradient-text bg-fire">VPN</span> that <span className="font-semibold gradient-text bg-fire">protects</span> your internet data, keeping your data packets <span className="font-semibold gradient-text bg-fire">secure and anonymous.</span>
            </span>
            <div className="flex items-center mt-[7px] lg:mt-[22px] space-x-[11px] lg:space-x-[14px] w-full lg:w-auto">
              <button className="flex justify-center items-center w-full lg:w-[190px] 2xl:w-[13.194vw] bg-white space-x-2 button-text font-bold text-black" onClick={() => handleClick(5)} disabled={doing[5]}>
                {doing[5] ?
                  <img className="prive-loading1" src="images/spinner2.png" alt="" />
                  :
                  <>
                    <img className="w-[24px] h-[24px] 2xl:w-[1.666vw] 2xl:h-[1.666vw]" src="images/ic_sharp-apple.svg" alt="" />
                    <span>App Store</span>
                  </>
                }
              </button>
              <button className="flex justify-center items-center w-full lg:w-[190px] 2xl:w-[13.194vw] bg-black space-x-2 button-text font-bold border border-white" onClick={() => handleClick(6)} disabled={doing[6]}>
                {doing[6] ?
                  <img className="prive-loading1" src="images/spinner1.png" alt="" />
                  :
                  <>
                    <img className="w-[24px] h-[24px] 2xl:w-[1.666vw] 2xl:h-[1.666vw]" src="images/uil_android.svg" alt="" />
                    <span>Google Play</span>
                  </>
                }
              </button>
            </div>
          </div>
        </section>

        {/* Three Plans */}
        <section className="flex flex-col items-center mt-[150px] lg:mt-[343px] threeplans" ref={shopPlansRef}>
          <span className="relative z-[2] threetext gradient-text bg-fire font-semibold text-center whitespace-nowrap">Three Plans. <br className="block lg:hidden" />Zero Commitment.</span>
          <span className="relative z-[2] mt-[7px] lg:mt-1 subtitle1 font-medium">No contracts. Cancel anytime.</span>
          <div className="flex md:justify-center mt-[46px] lg:mt-[11px] w-[220px] tiny:w-[365px]">
            <div className="w-full">
              <Slider initialSlide={screenWidth > 1024 ? 1:2} swipe={showArrows} dots={false} arrows={showArrows} prevArrow={<div><img className="animated-arrow1" src="images/prev.png" alt="" /></div>} nextArrow={<div><img className="animated-arrow2" src="images/next.png" alt="" /></div>} ref={plansRef}>
                <div className="prive-carousel-item flex-col items-center px-[13px] tiny:px-[57.5px]">
                  <div className="relative">
                    <img className="relative z-[2] tiny:w-[250px] tiny:h-[250px]" src="images/plan1.png" alt="" />
                    <img className="block tiny:hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[375px]" src="images/Light 3.png" alt="" />
                    <img className="hidden tiny:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[848px]" src="images/Light 4.png" alt="" />
                  </div>
                  <span className="mt-[9px] tiny:mt-0 text-[20px] leading-[23.87px] tiny:text-[24px] tiny:leading-[28.64px] font-bold tiny:font-semibold relative ">$35/Line</span>
                  <div className="flex flex-col items-start space-y-[14px] mt-[11px] tiny:mt-[15px]">
                    <button className="p-[1px] bg-fire relative pointer-events-auto w-[175px] tiny:w-[225px] dark" onClick={() => handleClickTry(0)} disabled={trying[0]}>
                      <div className="flex justify-center items-center bg-sand w-full h-full rounded-[22.5px] relative z-[2]">
                        {trying[0] ?
                          <img className="prive-loading1" src="images/spinner2.png" alt="" />
                          :
                          <span className="button-text font-medium gradient-text bg-white">Try Now</span>
                        }
                      </div>
                      <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
                    </button>
                    <div className="mt-[14px] tiny:mt-6 text-[12px] leading-[22px] tiny:text-[18px] tiny:leading-[26.5px] font-extralight">
                      • Unlimited Talk & Text<br />
                      • 5GB of 5G per month<br />
                      • Free Unlimited VPN<br />
                      • Wifi Calling & Text<br />
                      • Free Mobile Hotspot
                    </div>
                  </div>
                </div>
                <div className="prive-carousel-item flex-col items-center px-[13px] tiny:px-[57.5px]">
                  <div className="relative">
                    <img className="relative z-[2] tiny:w-[250px] tiny:h-[250px]" src="images/plan2.png" alt="" />
                    <img className="block tiny:hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[375px]" src="images/Light 3.png" alt="" />
                    <img className="hidden tiny:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[848px]" src="images/Light 4.png" alt="" />
                  </div>
                  <span className="mt-[9px] tiny:mt-0 text-[20px] leading-[23.87px] tiny:text-[24px] lg:leading-[28.64px] font-bold lg:font-semibold relative">$45/Line</span>
                  <div className="flex flex-col items-start space-y-[14px] mt-[11px] tiny:mt-[15px]">
                    <button className="p-[1px] bg-fire relative pointer-events-auto w-[175px] tiny:w-[225px] dark" onClick={() => handleClickTry(1)} disabled={trying[1]}>
                      <div className="flex justify-center items-center bg-sand w-full h-full rounded-[22.5px] relative z-[2]">
                        {trying[1] ?
                          <img className="prive-loading1" src="images/spinner2.png" alt="" />
                          :
                          <span className="button-text font-medium gradient-text bg-white">Try Now</span>
                        }
                      </div>
                      <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
                    </button>
                    <div className="mt-[14px] tiny:mt-6 text-[12px] leading-[22px] tiny:text-[18px] tiny:leading-[26.5px] font-extralight">
                      • Unlimited Talk & Text<br />
                      • 15GB of 5G per month<br />
                      • Free Unlimited VPN<br />
                      • Wifi Calling & Text<br />
                      • Free Mobile Hotspot
                    </div>
                  </div>
                </div>
                <div className="prive-carousel-item flex-col items-center px-[13px] tiny:px-[57.5px]">
                  <div className="relative">
                    <img className="relative z-[2] tiny:w-[250px] tiny:h-[250px]" src="images/plan3.png" alt="" />
                    <img className="block tiny:hidden absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[375px]" src="images/Light 3.png" alt="" />
                    <img className="hidden tiny:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[848px]" src="images/Light 4.png" alt="" />
                  </div>
                  <span className="mt-[9px] tiny:mt-0 text-[20px] leading-[23.87px] tiny:text-[24px] tiny:leading-[28.64px] font-bold tiny:font-semibold relative">$55/Line</span>
                  <div className="flex flex-col items-start space-y-[14px] mt-[11px] tiny:mt-[15px]">
                    <button className="p-[1px] bg-fire relative pointer-events-auto w-[175px] tiny:w-[225px] dark" onClick={() => handleClickTry(2)} disabled={trying[2]}>
                      <div className="flex justify-center items-center bg-sand w-full h-full relative z-[2]">
                        {trying[2] ?
                          <img className="prive-loading1" src="images/spinner2.png" alt="" />
                          :
                          <span className="button-text font-medium gradient-text bg-white">Try Now</span>
                        }
                      </div>
                      <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
                    </button>
                    <div className="mt-[14px] tiny:mt-6 text-[12px] leading-[22px] tiny:text-[18px] tiny:leading-[26.5px] font-extralight">
                      • Unlimited Talk & Text<br />
                      • 30GB of 5G per month<br />
                      • Free Unlimited VPN<br />
                      • Wifi Calling & Text<br />
                      • Free Mobile Hotspot
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>

        {/* Swiss Privacy */}
        <section className="flex flex-col items-center mt-[150px] lg:mt-[193.06px] policy">
          <span className="subtitle1 font-medium">Protected by</span>
          <span className="lg:mt-[3px] gradient-text bg-fire text-[40px] leading-[47.73px] lg:text-[62.21px] lg:leading-[74.24px] 2xl:text-[4.3201388vw] 2xl:leading-[5.15555vw] font-semibold">Swiss Privacy</span>
          <img className="w-[220px] h-[219px] lg:w-auto lg:h-auto" src="images/plus-sign 1.png" alt="" />
          <span className="subtitle sm:max-w-[70%] text-center">Prive is based in Switzerland, which has the worlds most secure privacy laws. Our Swiss jurisdiction provides broad protections to users, including protection against warrantless surveillance and foreign governments that do not respect fundamental human rights.</span>
          <button className="mt-[30px] sm:mt-[28.9px] w-full sm:w-[188.57px] 2xl:w-[13.0951vw] p-[1px] bg-fire button-text font-medium relative dark" onClick={() => handleClick(7)} disabled={doing[7]}>
            <div className="flex justify-center items-center bg-sand px-6 w-full h-full space-x-[10.41px] relative z-[2]">
              {doing[7] ?
                <img className="prive-loading1" src="images/spinner2.png" alt="" />
                :
                <>
                  <span className="gradient-text bg-white">Learn More</span>
                  <img className="arrow-right" src="images/arrow.svg" alt="" />
                  <img className="arrow-right-hover" src="images/arrow-right-hover.svg" alt="" />
                </>
              }
            </div>
            <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
          </button>
        </section>

        {/* Family Plans */}
        <section className="flex flex-col items-center mt-[152px] lg:mt-[180px] familyplans" ref={familyPlansRef}>
          <span className="subtitle1 font-medium">Protect your loved ones & save with</span>
          <span className="mt-[5px] title lg:font-medium gradient-text bg-fire">Family Plans</span>
          <div className="my-8 lg:my-11 relative">
            <img className="phone w-32 lg:w-44 relative z-[3]" src="images/Phone 3.png" alt="" />
            <img className="phone phone-left w-32 lg:w-44 absolute top-0 right-[100px] lg:right-[140px] z-[2]" src="images/Phone 1.png" alt="" />
            <img className="phone phone-right w-32 lg:w-44 absolute top-0 left-[100px] lg:left-[140px] z-[2]" src="images/Phone 2.png" alt="" />
            <img className="phone-back min-w-[900px] lg:min-w-[1300px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-1" src="images/Light 2.png" alt="" />
          </div>
          <span className="subtitle text-center relative">Get protection for all of your family members' devices and save big on pricing.</span>
          <button className="mt-[23px] sm:mt-[31px] w-full sm:w-[231px] 2xl:w-[16.0416vw] p-[1px] bg-fire button-text font-medium relative dark">
            <div className="flex justify-center items-center bg-sand w-full h-full space-x-[10px] relative z-[2]" onClick={() => handleClick(8)} disabled={doing[8]}>
              {doing[8] ?
                <img className="prive-loading1" src="images/spinner2.png" alt="" />
                :
                <>
                  <span className="gradient-text bg-white">See Family Plans</span>
                  <img className="arrow-right" src="images/arrow.svg" alt="" />
                  <img className="arrow-right-hover" src="images/arrow-right-hover.svg" alt="" />
                </>
              }
            </div>
            <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
          </button>
        </section>

        {/* Try it Free */}
        <section className="flex flex-col lg:flex-row justify-between items-center mt-[152px] lg:mt-[221px] px-[22px] lg:px-[50px] 2xl:px-[3.472vw] pt-5 lg:pt-0 bg-grey2 relative rounded-[20px] tryitfree">
          <div className="flex flex-col items-center lg:items-start">
            <div className="text-[32px] leading-[38.19px] lg:text-[45px] lg:leading-[53.7px] trytext font-semibold text-center lg:text-left">Try Prive for Free</div>
            <div className="mt-[5px] lg:mt-2 subtitle text-center lg:text-left">
              Prive brings the fastest 5G network & combines it with your favorite privacy features.{"\u00a0\u00a0"}<br className="block md:hidden" />
              <label className="font-semibold">Try Prive For free today.</label>
            </div>
            <button className="mt-[14px] lg:mt-4 w-[206.4px] lg:w-[262.71px] 2xl:w-[18.24375vw] p-[1px] bg-fire button-text font-medium relative dark">
              <div className="flex justify-center items-center bg-sand w-full h-full space-x-[10px] relative z-[2]" onClick={() => handleClick(9)} disabled={doing[9]}>
                {doing[9] ?
                  <img className="prive-loading1" src="images/spinner2.png" alt="" />
                  :
                  <>
                    <span className="gradient-text bg-white">5 Day Free Trial</span>
                    <img className="arrow-right" src="images/arrow.svg" alt="" />
                    <img className="arrow-right-hover" src="images/arrow-right-hover.svg" alt="" />
                  </>
                }
              </div>
              <div className="absolute top-0 left-0 bg-fire blur-sm rounded-3xl w-full h-full opacity-30" />
            </button>
          </div>
          <img className="block lg:hidden w-[263px] mt-[6px]" src="images/bottom image cropped.png" alt="" />
          <img className="hidden lg:block w-[373px] h-[230px] 2xl:min-h-[14.9739vw]" src="images/bottom image cropped.png" alt="" />
        </section>

        {/* Footer */}
        <Footer />
      </div>
      {!agreed &&
        <div className="flex justify-between items-center fixed z-[6] bottom-[27px] left-1/2 -translate-x-1/2 w-[calc(100%-48px)] mobile:w-[calc(100%-250px)] rounded-tiny px-[10px] py-[9px] md:pl-[22px] md:pr-[7px] md:py-[6px] 2xl:pl-[1.5277vw] 2xl:pr-[0.48611vw] 2xl:py-[0.41666vw] bg-cookieback backdrop-blur-sm space-x-6">
          <span className="flex-1 text-[14px] 2xl:text-[0.9722vw] font-medium">Prive uses cookies on this site to enhance your user experience, understand site usage, and assist in our marketing efforts.</span>
          <button className="flex justify-center items-center w-[80px] h-[40px] md:w-[100px] md:h-[48px] 2xl:w-[6.944vw] 2xl:h-[3.333vw] font-bold text-[13px] leading-[15.51px] md:text-[14px] md:leading-[16.71px] 2xl:text-[0.972222vw] 2xl:leading-[1.16041vw] text-black bg-white backdrop-blur-sm rounded-tiny agree-button" onClick={handleAgree}>
            I Agree
          </button>
        </div>
      }
    </div>
  );
};

export default Landing;
