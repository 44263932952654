import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RefContext } from "../contexts/RefContextProvider";

const Footer = () => {
  const { footerRef } = useContext(RefContext);
  return (
    <footer className="mt-[87px] md:mt-[89px]" ref={footerRef}>
      <div className="grid footergrid gap-x-5 gap-y-[17px]">
        <div className="flex flex-col items-start space-y-[5px] md:space-y-[18px]">
          <div className="flex items-center space-x-[11.45px] text-[36px] leading-[42.96px] font-semibold">
            <img src="images/logo.svg" alt="" />
            <span>PRIVE</span>
          </div>
          <span className="text-[16px] leading-[22px]">The Privacy 5G Network</span>
        </div>
        <div className="flex flex-col space-y-2 md:space-y-7 mt-[7px] tiny:mt-0">
          <div className="text-[18px] leading-[22px] md:text-[28px] md:leading-[22px] text-grey1 font-medium">Service Features</div>
          <div className="flex items-center space-x-7 md:space-x-[39px] text-[13px] leading-[15.51px] md:text-[16px] md:leading-[19.09px]">
            <div className="flex flex-col space-y-4">
              <Link to="/waitlist">Shop Plans</Link>
              <Link to="/waitlist">Family Plans</Link>
            </div>
            <div className="flex flex-col space-y-4">
              <Link to="/waitlist">Privacy Protection</Link>
              <Link to="/waitlist">Prive App</Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-2 md:space-y-7">
          <div className="text-[18px] leading-[22px] md:text-[28px] md:leading-[22px] text-grey1 font-medium">Contact Us</div>
          <div className="flex flex-col space-y-[10px] text-[13px] leading-[15.51px] md:text-[16px] md:leading-[19.09px]">
            <div className="flex items-center space-x-2">
              <img src="images/envelop.svg" alt="" />
              <span>contact@privemobile.com</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="images/phone-call.svg" alt="" />
              <span>Coming Soon</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-[7px] md:space-y-7">
          <div className="hidden md:block text-[18px] leading-[22px] md:text-[28px] md:leading-[22px] text-grey1 font-medium">Social Media</div>
          <div className="flex justify-center tiny:justify-start items-center space-x-[33px] tiny:space-x-[18px]">
            <a href="http://facebook.com/privemobile" ><img className="cursor-pointer" src="images/social/fb.svg" alt="" /></a>
            <a href="http://twitter.com/privemobile" ><img className="cursor-pointer" src="images/social/tw.svg" alt="" /></a>
            <Link to="/waitlist"><img className="cursor-pointer" src="images/social/ig.svg" alt="" /></Link>
          </div>
        </div>
      </div>
      <div className="mt-[42px] tiny:mt-[75px] w-full h-[1px] bg-fire" />
      <div className="tiny:mt-[22px] mt-[26px] text-[12px] leading-[14.32px] tiny:text-[16px] tiny:leading-[22px] text-center">@ 2023 Privemobile.com{"\u00a0\u00a0\u00a0"}All rights reserved</div>
    </footer>
  );
};

export default Footer;
